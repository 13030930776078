import React, { useState, useEffect } from 'react';
import { Layout, Row, theme } from 'antd';
import ProductCard from './ProductCard';
import Head from './Head';
import axios from 'axios';

const { Content, Footer } = Layout;

const MainPage = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [products, setProducts] = useState([]); // 存储产品数据
  const [loading, setLoading] = useState(true); // 加载状态

  useEffect(() => {
    // 直接发送请求
    axios
      .post('http://107.22.94.72:8080/mainPage/getPage', {})
      .then((response) => {
        setProducts(response.data); // 设置产品数据
        setLoading(false); // 数据加载完成
      })
      .catch((error) => {
        console.error('Failed to fetch data:', error);
        setLoading(false);
      });
  }, []);

  // 如果正在加载，显示加载中状态
  if (loading) {
    return <div>Loading...</div>;
  }

  // 如果没有产品数据，显示空状态
  if (!products.length) {
    return <div>No products available.</div>;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Head />

      <Content
        style={{
          padding: '64px 0px',
          width: '100%',
        }}
      >
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 24,
            borderRadius: borderRadiusLG,
          }}
        >
          <Row gutter={[16, 16]}>
            {/* 显示产品卡片 */}
            {products.map((product) => (
              <ProductCard key={product.id} product={product} />
            ))}
          </Row>
        </div>
      </Content>

      <Footer style={{ textAlign: 'center' }}>
        Ant Design ©{new Date().getFullYear()} Created by Ant UED
      </Footer>
    </Layout>
  );
};

export default MainPage;


