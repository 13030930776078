
import React, { useState } from 'react';
import {Layout, Input, Button, Form, Select, message, theme } from 'antd';
import axios from 'axios';
import Head from './Head';
import {categories} from './Categories';

const { TextArea } = Input;
const { Option, OptGroup } = Select;
const {Content, Footer } = Layout;
const GOOGLE_MAPS_API_KEY = 'AIzaSyAh_rf4NsQYWOxv_jPAriQvJM4guou2QEM'; // Replace with your Google Maps API key

const AddProductForm = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [product, setProduct] = useState({
    name: '',
    description: '',
    category: '',
    price: '',
    userId: 1,
    isSold: 0, // The default setting is Unsold
  });
  const [location, setLocation] = useState({
    latitude: 0,
    longitude: 0,
    address: '',
  });
  const [locationInput, setLocationInput] = useState(''); // The address entered by the user

  // Parse the address and get the latitude and longitude
  const geocodeAddress = async (address) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&language=en&key=${GOOGLE_MAPS_API_KEY}`
      );

      if (response.data.status === 'OK') {
        const result = response.data.results[0];
        const latitude = result.geometry.location.lat;
        const longitude = result.geometry.location.lng;
        const formattedAddress = result.formatted_address;

        // Update the location's latitude, longitude, and address information
        setLocation({
          latitude,
          longitude,
          address: formattedAddress,
        });

        message.success('Location parsed successfully!');
      } else {
        message.error('Failed to parse the location. Please enter a valid address.');
      }
    } catch (error) {
      console.error('Error fetching location:', error);
      message.error('Error occurred while fetching location. Please try again.');
    }
  };

  // Submit form: Create product first, then create location
  const handleSubmit = async () => {
    if (!product.name || !product.price || !product.category || !location.address) {
      message.error('Name, Price, Category, and Location are required fields!');
      return;
    }

    try {
      // Step 1: Create a product
      const createProductResponse = await axios.post('http://localhost:8080/product/createProduct', {
        name: product.name,
        description: product.description,
        category: product.category,
        price: product.price,
        userId: product.userId,
        isSold: product.isSold,
      });

      const productId = createProductResponse.data; // Assume that the returned data contains productId
      console.log(productId
      )
      console.log(location)
      // Step 2: Create a location using productId
      await axios.post('http://localhost:8080/product/location/createOrUpdateLocation', {
        targetId: productId,
        latitude: location.latitude,
        longitude: location.longitude,
        address: location.address,
      });

      message.success('Product and location added successfully!');

      // Clear the form and address input
      setProduct({
        name: '',
        description: '',
        category: '',
        price: '',
        userId: '',
        isSold: 0,
      });
      setLocation({
        latitude: 0,
        longitude: 0,
        address: '',
      });
      setLocationInput('');
    } catch (error) {
      console.error('Error adding product or location:', error);
      message.error('Failed to add product or location. Please try again.');
    }
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Head/>
      <Content
        style={{
          padding: '64px 0px',
          width: '100%',
        }}
      >
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 24,
            borderRadius: borderRadiusLG,
          }}
        >
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Product Name"
            required
            rules={[{ required: true, message: 'Product name cannot be empty' }]}
          >
            <Input
              placeholder="Enter product name"
              name="name"
              value={product.name}
              onChange={(e) => setProduct({ ...product, name: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Description">
            <TextArea
              placeholder="Enter product description"
              name="description"
              value={product.description}
              onChange={(e) => setProduct({ ...product, description: e.target.value })}
              rows={4}
            />
          </Form.Item>
          <Form.Item
            label="Category"
            required
            rules={[{ required: true, message: 'Category cannot be empty' }]}
          >
            <Select
              placeholder="Select a category"
              value={product.category}
              onChange={(value) => setProduct({ ...product, category: value })}
            >
              {/* Furniture Category */}
              {categories.map(category => (
                <OptGroup key={category.label} label={category.label}>
                    {category.options.map(option => (
                        <Option key={option} value={option}>
                            {option}
                        </Option>
                    ))}
                </OptGroup>
            ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Price"
            required
            rules={[{ required: true, message: 'Price cannot be empty' }]}
          >
            <Input
              type="number"
              placeholder="Enter product price"
              name="price"
              value={product.price}
              onChange={(e) => setProduct({ ...product, price: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="Location"
            required
            rules={[{ required: true, message: 'Location cannot be empty' }]}
          >
            <Input
              placeholder="Enter location (e.g., Baltimore, MD)"
              value={locationInput}
              onChange={(e) => setLocationInput(e.target.value)}
              onBlur={() => geocodeAddress(locationInput)} // Resolve address when losing focus
            />
          </Form.Item>
          <Form.Item label="Picture URLs (optional)">
            <TextArea
              placeholder="Enter picture URLs separated by commas"
              name="pictureUrls"
              value={product.pictureUrls?.join(',') || ''}
              onChange={(e) =>
                setProduct({ ...product, pictureUrls: e.target.value.split(',') })
              }
              rows={2}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Add Product
            </Button>
          </Form.Item>
        </Form>
      </div>
      </Content>
    </Layout>
  );
};

export default AddProductForm;
