import React from 'react';
import { Col, Carousel, Card } from 'antd';
import { Link } from 'react-router-dom';

const { Meta } = Card;

const placeholderImage = 'https://via.placeholder.com/240x200.png?text=Image+Not+Available';

const ProductCard = ({ product }) => (
  <Col span={4} style={{ padding: '10px' }}> {/* 设置每个卡片的列宽 */}
    <Link to={`/item/${product.productId}`}>
      <Card
        hoverable
        cover={
          <Carousel autoplay>
            {product.pictureUrls.map((image, index) => (
              <div key={index}>
                <img
                  alt={`image${index}`}
                  src={image ? image : placeholderImage}
                  onError={(e) => { e.target.src = placeholderImage; }} // 占位符
                  style={{ width: '100%', height: '180px', objectFit: 'cover' }}
                />
              </div>
            ))}
          </Carousel>
        }
      >
        <Meta title={product.name} description={`$${product.price}`} />
        <div style={{ marginTop: '8px', fontSize: '14px', color: 'gray' }}>
          {product.location.address}
        </div>
      </Card>
    </Link>
  </Col>
);

export default ProductCard;
