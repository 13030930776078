import React, { useEffect, useState } from 'react';
import { List, Card, Tag, Spin, message, Layout, theme } from 'antd';
import axios from 'axios';
import Head from './Head';

const { Content } = Layout;

const FavouritesList = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [favourites, setFavourites] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFavourites = async () => {
      try {
        // Request favorites data, userId is 1
        const response = await axios.post('http://107.22.94.72:8080/favourites/getFavourites', {
          userId: 1,
        });
        
        setFavourites(response.data); // Set favorites data
        setLoading(false); 
      } catch (error) {
        console.error('Error fetching favourites:', error);
        message.error('Failed to load favourites. Please try again.');
        setLoading(false);
      }
    };

    fetchFavourites();
  }, []);

  return (
    <Layout style={{ minHeight: '100vh' }}>
    <Head/>
    <Content
      style={{
        padding: '64px 0px',
        width: '100%',
      }}
    >
      <div
        style={{
          background: colorBgContainer,
          minHeight: 280,
          padding: 24,
          borderRadius: borderRadiusLG,
        }}
      >
      <h2>My Favourites</h2>
      {loading ? (
        <Spin size="large" /> // Loading status
      ) : (
        <List
          dataSource={favourites}
          renderItem={(item) => (
<List.Item>
  <Card
    style={{
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr auto', // 1fr width on the left, adaptive content width on the right
      alignItems: 'center',
    }}
    bodyStyle={{ padding: '10px' }}
  >
    {/* Left side: Product name and address */}
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: '0 0 100px', marginRight: '16px' }}>
        <img
          alt={item.name}
          src={item.pictureUrls[0]}
          style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
        />
      </div>
      <div>
        <h3 style={{ margin: 0 }}>{item.name}</h3>
        <p style={{ margin: 0, color: '#888' }}>{item.location?.address}</p>
      </div>
    </div>

    {/* Right: Price and Status */}
    <div style={{ textAlign: 'right' }}>
      <p style={{ margin: 0 }}>Price: ${item.price}</p>
      {item.isSold ? (
        <Tag color="red" style={{ marginTop: '8px' }}>Sold Out</Tag>
      ) : (
        <Tag color="green" style={{ marginTop: '8px' }}>Available</Tag>
      )}
    </div>
  </Card>
</List.Item>


          )}
        />
      )}
    </div>
  </Content>
</Layout>
  );
};

export default FavouritesList;
