import React from 'react';
import {
  RouterProvider,
  createHashRouter,
} from 'react-router-dom';
import MainPage from './MainPage';
import ItemDetailPage from './ItemDetailPage';
import AddProductForm from './AddProductForm';
import FavouritesList from './FavouritesList';
import MyItems from './MyItems';
import RegisterForm from './RegisterForm';
import LoginForm from './LoginForm';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute

const router = createHashRouter([
  {
    path: '/',
    element: (
      <ProtectedRoute> {/* Protect the MainPage */}
        <MainPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/item/:productId',
    element: (
      <ProtectedRoute> {/* Protect the ItemDetailPage */}
        <ItemDetailPage />
      </ProtectedRoute>
    ),
  },
  {
    path: '/account/add-product',
    element: (
      <ProtectedRoute> {/* Protect the AddProductForm */}
        <AddProductForm />
      </ProtectedRoute>
    ),
  },
  {
    path: '/account/saved',
    element: (
      <ProtectedRoute>
        <FavouritesList />
      </ProtectedRoute>
    ),
  },
  {
    path: '/account/my-items',
    element: (
      <ProtectedRoute>
        <MyItems />
      </ProtectedRoute>
    ),
  },
  {
    path: '/register',
    element: <RegisterForm />,
  },
  {
    path: '/login',
    element: <LoginForm />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
