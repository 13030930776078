import React, { useEffect, useState } from 'react';
import { List, Card, Button, Spin, message, Layout, theme, Popconfirm } from 'antd';
import axios from 'axios';
import Head from './Head';

const { Content } = Layout;

const MyItems = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const [activeItems, setActiveItems] = useState([]);
  const [inactiveItems, setInactiveItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        setLoading(true);
        // 获取 Active Items 和 Inactive Items
        const [activeResponse, inactiveResponse] = await Promise.all([
          axios.post('http://107.22.94.72:8080/product/getAvailableProductsByUserId', { userId: 1 }),
          axios.post('http://107.22.94.72:8080/product/getSoldProductsByUserId', { userId: 1 }),
        ]);
        setActiveItems(activeResponse.data);
        setInactiveItems(inactiveResponse.data);
      } catch (error) {
        console.error('Error fetching items:', error);
        message.error('Failed to load items. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  // 处理编辑功能
  const handleEdit = (item) => {
    message.info(`Editing item: ${item.name}`);
    // 在这里实现编辑逻辑，比如打开一个表单
  };

  // 处理使其失效
  const handleDeactivate = async (itemId) => {
    try {
      // 发送请求将商品设置为失效
      await axios.post('http://107.22.94.72:8080/product/deactivateProduct', { productId: itemId });
      message.success('Item deactivated successfully!');
      // 更新本地状态
      setActiveItems((prev) => prev.filter((item) => item.productId !== itemId));
    } catch (error) {
      console.error('Error deactivating item:', error);
      message.error('Failed to deactivate item. Please try again.');
    }
  };

  const renderItemCard = (item, isActive) => (
    <List.Item>
      <Card
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          alignItems: 'center',
        }}
        bodyStyle={{ padding: '10px' }}
      >
        {/* 左侧：商品图片、名称和价格 */}
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: '0 0 100px', marginRight: '16px' }}>
            <img
              alt={item.name}
              src={item.pictureUrls[0]}
              style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '8px' }}
            />
          </div>
          <div>
            <h3 style={{ margin: 0 }}>{item.name}</h3>
            <p style={{ margin: 0, color: '#888' }}>Price: ${item.price.toFixed(2)}</p>
          </div>
        </div>

        {/* 右侧：按钮操作 */}
        {isActive && (
          <div style={{ textAlign: 'right' }}>
            <Button
              type="link"
              onClick={() => handleEdit(item)}
              style={{ marginRight: '10px' }}
            >
              Edit
            </Button>
            <Popconfirm
              title="Are you sure you want to deactivate this item?"
              onConfirm={() => handleDeactivate(item.productId)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" danger>
                Deactivate
              </Button>
            </Popconfirm>
          </div>
        )}
      </Card>
    </List.Item>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Head />
      <Content
        style={{
          padding: '64px 0px',
          width: '100%',
        }}
      >
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 24,
            borderRadius: borderRadiusLG,
          }}
        >
          <h2>My Items</h2>
          {loading ? (
            <Spin size="large" />
          ) : (
            <>
              {/* Active Items */}
              <div style={{ marginBottom: '40px' }}>
                <h3>Active Items</h3>
                {activeItems.length > 0 ? (
                  <List
                    dataSource={activeItems}
                    renderItem={(item) => renderItemCard(item, true)} // 传递 isActive 标记
                  />
                ) : (
                  <p>No active items available</p>
                )}
              </div>

              {/* Inactive Items */}
              <div>
                <h3>Inactive Items</h3>
                {inactiveItems.length > 0 ? (
                  <List
                    dataSource={inactiveItems}
                    renderItem={(item) => renderItemCard(item, false)} // 不传递按钮逻辑
                  />
                ) : (
                  <p>No inactive items available</p>
                )}
              </div>
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default MyItems;
