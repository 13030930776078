import React from 'react';
import { Layout, Input, Button, Row, Col, Menu, Avatar, Dropdown, Select } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { categories } from './Categories';
const { Search } = Input;
const { Header } = Layout;
const { Option, OptGroup } = Select;


// Create a drop-down menu for each category
const createDropdownMenu = (options) => (
    <Menu>
        {options.map((option) => (
            <Menu.Item key={option}>{option}</Menu.Item>
        ))}
    </Menu>
);

const Head = () => {
    const navigate = useNavigate();
    return (
        <Header style={{ backgroundColor: '#fff', width: '100%', padding: '0 0px' }}>
            <Row justify="space-between" align="middle" style={{ width: '100%' }}>
                <Col style={{ display: 'flex', alignItems: 'center' }} span={6}>
                    <div className="demo-logo" style={{ fontSize: '24px', fontWeight: 'bold' }}>
                        ChickenYouSoBeautiful
                    </div>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center' }} span={6}>
                    <Search placeholder="Search for sale" enterButton="Search" size="large" />
                </Col>
                <Col span={6}>
                    <Menu mode="horizontal" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', backgroundColor: 'transparent', borderBottom: 'none' }}>
                        <Menu.Item key="saved"  onClick={() => navigate('/account/saved')}>
                            Saved
                        </Menu.Item>
                        <Menu.Item key="inbox">
                            Inbox
                        </Menu.Item>
                        <Menu.Item key="post" onClick={() => navigate('/account/add-product')}>
                            Post an Item
                        </Menu.Item>
                        <Menu.Item key="my-items" onClick={() => navigate('/account/my-items')}>
                            My Items
                        </Menu.Item>
                    </Menu>
                </Col>
                <Col style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} span={6}>
                    <Button type="primary" style={{ marginRight: '15px' }}>Get the app</Button>
                    <Dropdown overlay={<Menu><Menu.Item key="0"><a href="/profile">View Profile</a></Menu.Item><Menu.Item key="1"><a href="/settings">Account Settings</a></Menu.Item><Menu.Divider /><Menu.Item key="2"><a href="/logout">Log out</a></Menu.Item></Menu>} trigger={['click']}>
                        <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
                    </Dropdown>
                </Col>
            </Row>

            <Row justify="center" align="middle" style={{ width: '100%'}}>
                <Menu mode="horizontal" theme="dark" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    {categories.map((category) => (
                        <Menu.Item key={category.label}>
                            <Dropdown overlay={createDropdownMenu(category.options)} trigger={['hover']}>
                                <span style={{ color: '#fff', cursor: 'pointer' }}>
                                    {category.label} <DownOutlined />
                                </span>
                            </Dropdown>
                        </Menu.Item>
                    ))}
                </Menu>
            </Row>
        </Header>
    );
};

export default Head;

