// categories.js
export const categories = [
    {
        label: "Furniture",
        options: [
            "Sofas & Couches", "Coffee Tables", "Dining Tables", "Chairs", "Desks",
            "Bookshelves", "Cabinets & Storage", "Dressers", "Bed Frames"
        ]
    },
    {
        label: "Electronics",
        options: [
            "Mobile Phones",
            "Laptops",
            "Tablets",
            "Televisions",
            "Smart Watches",
            "Headphones & Earphones",
            "Cameras & Camcorders",
            "Speakers & Sound Systems",
            "Gaming Consoles",
            "Computer Accessories (Keyboards, Mice, Monitors)",
            "Storage Devices (External Hard Drives, USB Drives)",
            "Networking Equipment (Routers, Modems)",
            "Drones & Quadcopters",
            "Wearable Devices (Fitness Trackers, AR Glasses)"
        ]
    },    
    {
        label: "Vehicles",
        options: [
            "Cars", "Bicycles", "Electric Scooters", "Motorbikes", "Accessories"
        ]
    },
    {
        label: "Kitchenware",
        options: [
            "Cookware (Pots, Pans)", "Cutlery (Knives, Forks, Spoons)", "Dishware (Plates, Bowls)",
            "Glassware (Cups, Glasses)", "Small Appliances (Blenders, Microwaves)", "Storage Containers",
            "Baking Utensils", "Cooking Utensils (Spatulas, Whisks)", "Kettles & Coffee Makers"
        ]
    },
    {
        label: "Clothing",
        options: [
            "Tops (T-Shirts, Blouses)", "Bottoms (Pants, Skirts)", "Outerwear (Jackets, Coats)",
            "Footwear (Shoes, Boots)", "Accessories (Hats, Scarves)", "Formal Wear (Suits, Dresses)",
            "Sportswear (Gym Clothes, Sneakers)", "Children's Clothing"
        ]
    },
    {
        label: "Bedding",
        options: [
            "Mattresses", "Pillows", "Sheets & Pillowcases", "Blankets & Comforters",
            "Bedspreads", "Duvet Covers", "Mattress Protectors"
        ]
    }
];
