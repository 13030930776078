import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Layout, Input, Button, Row, Col, Carousel, Card, theme, Modal,List } from 'antd';
import axios from 'axios';
import Head from './Head';

const { Search } = Input;
const {Content, Footer } = Layout;
const { Meta } = Card;



const placeholderImage = 'https://via.placeholder.com/400x300.png?text=Image+Not+Available';

const ItemDetailPage = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const { productId } = useParams(); // Get productId from URL
  
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect Get product details
  const [isChatVisible, setIsChatVisible] = useState(false); // 控制聊天框显示状态
  const [messages, setMessages] = useState([]); // 消息列表
  const [newMessage, setNewMessage] = useState(''); // 新消息输入框的内容

  // 切换聊天框的显示状态
  const toggleChat = () => setIsChatVisible(!isChatVisible);

  // 处理发送消息逻辑
  const handleSendMessage = () => {
    if (newMessage.trim()) {
      // 模拟发送消息
      setMessages((prevMessages) => [...prevMessages, { sender: 'You', text: newMessage }]);
      setNewMessage('');
      // TODO: 在这里调用后端 API 发送消息
    }
  };
  // useEffect 获取产品详细信息
  useEffect(() => {
    axios.post('http://107.22.94.72:8080/product/getProduct', { productId: Number(productId)})
      .then(response => {
        setProduct(response.data);
        console.log('Product data:', response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Failed to fetch product details:', error);
        setLoading(false);
      });
  }, [productId]);
  
  if (loading) {
    return <div>Loading...</div>;
  }

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Head/>

      <Content
        style={{
          padding: '64px 0px',
          width: '100%',
        }}
      >
        <div
          style={{
            background: colorBgContainer,
            minHeight: 280,
            padding: 24,
            borderRadius: borderRadiusLG,
          }}
        >
        <Row gutter={[16, 16]} justify="center">
          <Col span={10}>
            <Carousel autoplay>
              {product.pictureUrls && product.pictureUrls.length > 0 ? (
                product.pictureUrls.map((url, index) => (
                  <div key={index}>
                    <img
                      src={url || placeholderImage}
                      alt={`Product image ${index + 1}`}
                      style={{ width: '100%', height: '400px', objectFit: 'cover' }}
                      onError={(e) => { e.target.src = placeholderImage; }}
                    />
                  </div>
                ))
              ) : (
                <img
                  src={placeholderImage}
                  alt="No image available"
                  style={{ width: '100%', height: '400px', objectFit: 'cover' }}
                />
              )}
            </Carousel>
          </Col>

          <Col span={8}>
            <Card bordered={false}>
              <h1>{product.name}</h1>
              <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#00aaff' }}>
                ${product.price}
              </div>
              <div style={{ marginTop: '16px', fontSize: '16px', color: 'gray' }}>
                {product.description}
              </div>
              <div style={{ marginTop: '8px', fontSize: '16px', color: 'gray' }}>
                Location: {product.location.address}
              </div>
              <Row gutter={16} style={{ marginTop: '20px' }}>
                <Col>
                  <Button type="primary" style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}>
                    Make Offer
                  </Button>
                </Col>
                <Col>
                  {/* 现有内容 */}
                  <Button
                          type="default"
                          style={{ backgroundColor: '#e0e0e0', color: '#000' }}
                          onClick={toggleChat} // 点击按钮切换聊天框状态
                        >
                          Ask
                        </Button>

                        {/* 聊天弹窗 */}
                        <Modal
                          title="Chat with Seller" // 聊天框标题
                          visible={isChatVisible} // 控制显示状态
                          onCancel={toggleChat} // 点击关闭按钮时隐藏聊天框
                          footer={null}
                          style={{ top: 20 }}
                        >
                          {/* 消息显示区域 */}
                          <div style={{ maxHeight: '400px', overflowY: 'auto', marginBottom: '16px' }}>
                            <List
                              dataSource={messages} // 数据源为消息列表
                              renderItem={(message) => (
                                <List.Item>
                                  <strong>{message.sender}: </strong> {/* 显示消息发送者 */}
                                  {message.text} {/* 显示消息内容 */}
                                </List.Item>
                              )}
                            />
                          </div>

                          {/* 消息输入区域 */}
                          <Input
                            placeholder="Type your message here..." // 输入框占位符
                            value={newMessage} // 输入框的内容绑定到 newMessage 状态
                            onChange={(e) => setNewMessage(e.target.value)} // 更新输入框内容
                            onPressEnter={handleSendMessage} // 按回车键发送消息
                          />
                          <Button type="primary" onClick={handleSendMessage} style={{ marginTop: '8px' }}>
                            Send
                          </Button>
                        </Modal>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        </div>
      </Content>

      <Button type="primary" onClick={() => navigate(-1)} style={{ margin: '16px' }}>
        Back to Main Page
      </Button>

      <Footer style={{ textAlign: 'center' }}>
        Ant Design ©{new Date().getFullYear()} Created by Ant UED
      </Footer>
    </Layout>
  );
};

export default ItemDetailPage;
